import { useMemo } from "react"

export const useAppBridgeHost = () => {
  return useMemo(() => {
    if (typeof window !== "undefined" && window.location) {
      return new URLSearchParams(window.location.search).get("host") || null
    }
    return null
  }, [])
}
