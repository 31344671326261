import React, { useState, useEffect } from "react"
import { StaticQuery, Link, graphql, navigate } from "gatsby"
import { Provider } from "@shopify/app-bridge-react"
import { AppProvider, Frame, Loading, FooterHelp, Link as PolarisLink } from "@shopify/polaris"
import { ReactifyApp } from "@reactify-apps/reactify-core-app"
import { useAppBridgeHost } from "../hooks/useAppBridgeHost"
import enTranslations from "@shopify/polaris/locales/en.json"

import "@shopify/polaris/build/esm/styles.css"
import "static/app.css"

const config = require("../../../.runtimeconfig.json").payments
const shopifyConfig = config?.shopify[process.env.NODE_ENV]
const firebaseConfig = config?.firebase

const query = graphql`
  query AppQuery {
    site {
      siteMetadata {
        brand
        website
      }
    }
  }
`

const AppLayout = props => {
  const host = useAppBridgeHost()

  const [shop, setShop] = useState(false)
  const [token, setToken] = useState(false)
  const [uid, setUID] = useState(false)
  const [firebase, setFirebase] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [isAuth, setAuth] = useState(false)

  useEffect(() => {
    const shopDomain = ReactifyApp.Auth.getShop()

    if (shopDomain) {
      setShop(shopDomain)
    } else {
      navigate(`/install/`, {
        replace: true,
      })
    }
  }, [])

  useEffect(() => {
    if (shop) {
      const authenticate = async () => {
        const isAuth = await ReactifyApp.Auth.isAuthenticated(navigate)
        if (isAuth) {
          setAuth(isAuth)
          setToken(ReactifyApp.Auth.getStorageToken())
          setUID(ReactifyApp.Auth.getStorageUID())
          ReactifyApp.Auth.setStorageHmac(window.location.search)
        }
      }
      authenticate()
    }
  }, [shop])

  useEffect(() => {
    if (isAuth && uid) {
      const app = import("firebase/app")
      const auth = import("firebase/auth")
      const database = import("firebase/firestore")

      Promise.all([app, auth, database]).then(async values => {
        const firebaseInstance = await ReactifyApp.Firebase.getFirebase(values[0], uid, {
          apiKey: firebaseConfig.web.api_key,
          authDomain: firebaseConfig.web.auth_domain,
          databaseURL: firebaseConfig.web.database_url,
          projectId: firebaseConfig.project_id,
        })

        if (process.env.NODE_ENV === "development")
          await firebaseInstance.firestore().settings({
            host: "localhost:8080",
            ssl: false,
          })

        setFirebase(firebaseInstance)
        setLoading(false)
      })
    }
  }, [isAuth, uid])

  const linkComponent = ({ url, external, children, ...rest }) =>
    external ? (
      <a href={url} target="_blank" rel="noopener noreferrer" {...rest}>
        {children}
      </a>
    ) : (
      <Link to={url} removeUnderline {...rest}>
        {children}
      </Link>
    )

  return (
    <AppProvider linkComponent={linkComponent} i18n={enTranslations}>
      <Provider
        config={{
          apiKey: shopifyConfig.api_key,
          host,
          shopOrigin: ReactifyApp.Auth.getShop(),
          forceRedirect: process.env.NODE_ENV !== "development",
        }}
      >
        {shop && firebase && !isLoading ? (
          <ReactifyApp.Firebase.Provider value={{ firebase, shop }}>
            <ReactifyApp.Shopify.Provider shop={shop} token={token} version={shopifyConfig.api_version} onNavigate={navigate}>
              {props.children}
              <StaticQuery
                query={query}
                render={data => (
                  <FooterHelp>
                    Learn more about{" "}
                    <PolarisLink url={"https://dotdev.com.au/"} external>
                      {data.site.siteMetadata.brand}
                    </PolarisLink>
                  </FooterHelp>
                )}
              />
            </ReactifyApp.Shopify.Provider>
          </ReactifyApp.Firebase.Provider>
        ) : (
          <Frame>
            <Loading />
          </Frame>
        )}
      </Provider>
    </AppProvider>
  )
}

export default AppLayout
